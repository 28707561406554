.settingsBankMiddle {
	border: 2px solid rgb(230,230,230);
	padding: 5%!important;
	margin-bottom: 2px;
}
.settingsBankBottom {
	background: #091F5C;
	padding: 4%!important;
	color: white;
}
.bankBtn {
	background: #F4F5FE;
	border: 1px solid rgb(225,225,225);
	border-radius: 10px;
	cursor: pointer;
}
.bankImg {
	width: 30%;
	height: auto;
}
.bankLogo {
	width: 35%;
	height: auto;
	margin: 0;
}
.iii {
	background: white;
	padding: 2% 4%;
	border-radius: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.addBankInput {
	border: none!important;
	padding: 0!important;
}
.addBankNav {
	border-radius: 5px;
	border: 1px solid rgb(220,220,220);
}