.rowCard {
  height: 400px;
  display: flex;
  padding: 30px;
}

.rowCard__text {
  width: 40%;
  display: flex;
  align-items: center;
}

.rowCard__textBox {
  width: 90%;
}

.rowCard__imgBox {
  flex: 1;
}

.rowCard__img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.rowCard__textBox h4 {
  color: #001e5a;
  margin-left: "30px";
  font-size: 20px;
}

.rowCard__textBox p {
  color: #62666c;
}

@media screen and (max-width: 600px) {
  .rowCard {
    display: block;
    height: fit-content;
    flex-direction: column;
  }

  .rowCard__text {
    width: 100%;
    margin-bottom: 15px;
    text-align: left !important;
  }
}
