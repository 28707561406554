.SettingsSection {
  &__container {
    // margin-top: 50px;
    // max-width: 450px !important;
  }
}

.input-settings {
    width: 50%!important;
    border: 2px solid rgb(220,220,220)!important;
    font-weight: light!important;
    outline: none!important;
    padding: 3.3% 2% 2% 2%!important;
    border-radius: 7px!important;
}
.input-settings-sub {
    width: 100%!important;
}
.input-settings:hover {
    border: 2px solid #6B799D!important;
    transition: .4s ease-out;
}
.inputTag {
  position: absolute;
  border-radius: 7px;
  background: rgb(245,245,245);
  color: #091F5C;
  padding: .8% .5%;
  z-index: 5;
  margin-top: -16px;
  margin-left: 30px;
}
.settingsMb {
  margin-bottom: 3%; 
}
.settingsBtnSave {
  border-radius: 7px!important;
  padding: 3% 5%!important;
}