@import "./global.scss";

.Marketplace {
  height: 1200px;

  &__tabs {
    margin-bottom: 3rem !important;
  }
  ul {
    border-bottom: 1px solid #b5b5b5 !important;
  }
  li.is-active > a {
    color: #091f5c !important;
    border: none;
    border-bottom: 3px solid #091f5c !important;
    padding-bottom: 3%;
    transition: 0.1s ease-out;
  }
  li > a {
    color: rgb(180, 180, 190);
    border: none;
    border-bottom: 3px solid transparent !important;
    padding-bottom: 3%;
  }
  li > a:hover {
    color: #091f5c !important;
    background: transparent !important;
    transition: 0.4s ease-out;
  }

  &__table.card {
    background-color: $light-secondary !important;
    border: 1px solid rgb(240, 240, 240) !important;
    border-radius: 8px !important;
    margin-bottom: 3% !important;
    color: $primary;
    .column {
      padding: 2.5% 5%;
      color: inherit;
    }

    &:hover {
      color: white;
      background-color: $primary !important;
      cursor: pointer;
    }
  }

  &__table.titleb {
    background-color: white !important;
    border: 1px solid rgb(240, 240, 240) !important;
    border-radius: 8px !important;
    margin-bottom: 3% !important;
    color: $primary;
    .column {
      padding: 2% 4%;
      color: inherit;
    }
  }

  &__filter {
    .balance_holder {
      background-color: $light-secondary;
      color: $secondary;
      & > .card {
        background-color: $primary;
        color: white;
      }
    }
  }
}
.slider-style {
  width: 100% !important;
  border: none !important;
  background: transparent !important;
  padding: 0 !important;
  text-align: left !important;
}
.slider-style-thumb {
  display: inline-block;
  width: 100% !important;
}
