@import '../styles/global.scss';

.SettingsNav {

  width: 98%;
  
  ul {
        border-bottom: 1px solid #b5b5b5!important;
    }
    li {
        width: auto!important;
    }
    li.is-active > a {
        color: #091F5C!important;
        border: none;
        border-bottom: 3px solid #091F5C!important;
        padding-bottom: 3%;
        transition: .1s ease-out;
    }
    li > a {
        color: rgb(180,180,190);
        border: none;
        border-bottom: 3px solid transparent!important;
        padding-bottom: 3%;
    }
    li > a:hover {
        color: #091F5C!important;
        background: transparent!important;
        transition: .4s ease-out;
    }
}
