@import "../../styles/global.scss";

.CreateDeck {
    &__table.card {
        border: 1px solid $light-secondary !important;
        margin-top: 0.5rem;
        margin-left: 0.1rem;
        margin-bottom: 0.5rem !important;
        padding-right: 0.3rem;
        .column {
            padding: 0.5rem;
            color: $primary;
        }
    
        &:hover{
            background-color: $light-secondary !important;
            cursor: pointer;
        }
    }
}