.hero {
    border: 1px solid;
    padding-top: 30px;
    padding-left: 50px;
    background-color: #001e5a;
    background-image: url("../../assets/img/background_lines.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100vh; */
}

.html * {
    font-family: 'Hind';
}

.navbar {
    padding-left: 30px;
    /* border: 1px solid; */
    /* height: 60px; */
    display: flex;
    align-items: center;
}

.navbar__logo {
    /* border: 1px solid red; */
    width: fit-content;
}

.logo {
    width: 100px;
    object-fit: contain;
}

.banner {
    /* display: grid;
  grid-template-columns: 1fr 1fr; */
    display: flex;
    justify-content: space-evenly;
}

.banner__text {
    flex: 1;
    /* border: 1px solid white; */
    padding-left: 30px;
    padding-top: 150px;
}

.banner__text div {
    /* border: 1px solid white; */
    padding-right: 120px;
}

.banner__text h1 {
    color: white;
    font-family: 'Averia Serif Libre';
    font-size: 50px;
    line-height: 1.3;
}

.banner__text p {
    color: white;
}

.banner__text button {
    background-color: #42a4d5;
    border: none;
    color: white;
    border-radius: 3px;
    padding: 16px 30px;
    font-size: 20px;
}

.banner__imgBox {
    /* border: 1px solid white; */
    flex: 1;
    position: relative;
}

.banner__imgBox2 {
    flex: 1;
    position: relative;
    left: -100%;
    -webkit-animation: left-to-right 0.5s linear forwards;
    animation: left-to-right 0.5s linear forwards;
}

@-webkit-keyframes left-to-right {
    from {
        left: -100%
    }
    to {
        left: 0
    }
}

@keyframes left-to-right {
    from {
        left: -100%
    }
    to {
        left: 0
    }
}

.banner__imgWrapper {
    /* border: 1px solid red; */
    max-width: 600px;
    margin-left: auto;
    height: fit-content;
}

.banner__imgWrapper2 {
    display: none;
    visibility: hidden;
    margin-left: auto;
    max-width: 600px;
    height: 30%;
}

.banner__img {
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 800px) {
    .banner__imgBox {
        display: none;
        visibility: hidden;
    }
    .banner__imgWrapper2 {
        display: block;
        visibility: visible;
    }
    .banner__text div {
        padding-right: 30px;
        text-align: left;
    }
    .banner__text {
        padding-top: 50px;
    }
    .hero {
        text-align: center;
        padding-left: 0px;
    }
    .banner__text button {
        width: 100%;
    }
}