.AppNavbar {
    margin-left: 18% !important;
    width: inherit;
    padding-right: 50px;
    background-color: white !important;
}

.app-navbar-notification-button-boarder {
    border-bottom-style: none;
    border-top-style: none;
    border-left-style: none;
}