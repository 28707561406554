@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Averia+Serif+Libre');
@import url('https://fonts.googleapis.com/css?family=Hind');
@import url('https://fonts.googleapis.com/css?family=Mulish');

// COLORS
$primary: #091F5C; 
$secondary: #6B799D;
$light-secondary: #F4F5FE;
$info: #209CEE;
$success: #23D160;
$warning: #FFDD57;
$danger: #FF3860;
$light: #F5F5F5;
$dark: #363636;

// TEXT
$text: #4A4A4A;
$link: #3273DC;
$body-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif;
$title-family: false;
$button-family: false;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

// SPACING HELPERS (.mt-3, .mx-auto, .p-5, etc)
$spacing-shortcuts: (
  "margin": "m",
  "padding": "p",
);
$spacing-directions: (
  "top": "t",
  "right": "r",
  "bottom": "b",
  "left": "l",
);
$spacing-horizontal: "x";
$spacing-vertical: "y";
$spacing-values: (
  "0": 0,
  "1": 0.25rem,
  "2": 0.5rem,
  "3": 1rem,
  "4": 1.5rem,
  "5": 3rem,
  "auto": auto,
);

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
@import "~bulma/bulma.sass";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

body {
  // Fix Bulma bug where columns cause
  // horizontal scroll on mobile.
  // https://github.com/jgthms/bulma/issues/449
  overflow-x: hidden;
}

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

.has-grey-borders {
  border: 1px solid #b5b5b5!important;
}

.content.is-vcentered {
  display: flex;
  flex-wrap: wrap;
  align-content: center; /* used this for multiple child */
  align-items: center; /* if an only child */
}

//NEW STYLING
input:focus, textarea:focus, select:focus, button:focus{
  outline: none!important;
}
.primary-color {
  color: #091F5C!important; 
}
.sec-color {
  color: #6B799D!important;
}
.no-border {
  border: none!important;
}
.trans-bg {
  background: transparent!important;
}
.faded-color {
  color: rgb(180,180,190);
}
.liner-color {
  display: block;
  border-bottom: 1px soild #b5b5b5;  
  width: 100%;
}
.grey {
  color: grey!important;
}
.move-center {
  text-align: center!important;
}

.cursorBtn {
  cursor: pointer;
}
.noCursor {
  cursor: text;
}