.tenderLogo {
    width: 45%;
    height: auto;
}
.sidebar-link {
    font-size: large;
    border-radius: 12px;
    width: 100%;
    a {  
        color: rgb(180,180,190);
    }
} 

.sidebar-link:hover {
    a { 
        background-color: #F8F9FE;
        color: #091F5C;
        border-radius: 12px;
    }
}

.sticky {
    width: 15%;
    position: fixed!important;
    margin-top: -25px!important;
}

.link-is-active {
    background-color: #F8F9FE;
    color: #091F5C;

    a {
        color: #091F5C;
    }
}

.sidebar-buttons {
    color: inherit!important;
    border: none!important;
    background: transparent;
}