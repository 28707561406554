@import './global.scss';

.Createdeck {
    // height: 1200px;

    li.is-active > a {
        color: $primary;
        font-weight: bold;
    }

    &__tabs > hr {
        
    }

    .deck-image {
        background-color: $light-secondary;
        height: auto;
        padding: 12% 5%;
        >figure>img {
            width: 90%;
            height: auto;
        }
    }

    .deck-image.content.is-vcentered {
        display: flex;
        flex-wrap: wrap;
        align-content: center; /* used this for multiple child */
        align-items: center; /* if an only child */
    }
}
.input-deck {
    width: 90%!important;
    border: 1px solid #363636!important;
    color: #6B799D!important;
}
.input-deck-sub {
    width: 90%!important;
}
.input-deck:hover {
    border: 1px solid #6B799D!important; 
}