@import "../styles/global.scss";

.FormModal {
    input::placeholder {
        color: gray
    }
    select {
        color: gray
    }
    .modal-card-body {
        border: 1px solid transparent !important;
        border-radius: 5px;
    }
    .modal-card-foot {
        border-top: 1px solid transparent !important;
    }
}