.howItWorks {
  padding: 30px;
  background-color: white;
}

.howItWorks h2 {
  text-align: center;
}

.howItWorks__cards {
  column-gap: 20px;
  row-gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
}

@media screen and (max-width: 960px) {
  .howItWorks__cards {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 568px) {
  .howItWorks__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
