.card {
    text-align: center;
    padding: 15px;
    min-width: 200px;
    border: 0.5px solid rgba(0, 30, 90, 0.25);
    border-width: thin;
    box-sizing: border-box;
    box-shadow: 4px 10px 20px rgba(66, 164, 213, 0.15);
    border-radius: 4px;
}

.card__imgBox {
    height: 50%;
}

.card__img {
    height: 100%;
    object-fit: contain;
}

.card__texts p {
    color: #62666c;
}

.card__texts h4 {
    color: #001e5a;
}