@import "../styles/global.scss";

.Notification {
    &__menu {
        width: 20rem !important;
        left: -6rem !important;

        hr.dropdown-divider:last-of-type {
            display: none !important;
            // background-color: red;
            // border: 1px solid red;
        }
    }
}
.bdr {
    border: none!important;
    border-right: 1px solid #b5b5b5!important;
    border-radius: 0px!important;
}