@import './global.scss';

.Activity {
  // .buttons.has-adds {
  //     >.button.is-right {
  //         margin: 0px !important;
  //         border-bottom-right-radius: 0%;
  //         border-top-right-radius: 0%;
  //         border-bottom-left-radius: 5%;
  //         border-top-left-radius: 5%;
  //         border-color: transparent;
  //         background-color: $light-secondary;
  //         color: $black;
          
  //         &.active-switch {
  //           background-color: $primary;
  //           color: white;
  //         }
  //       }
  //       >.button.is-left {
  //           margin: 0px !important;
  //           border-bottom-right-radius: 5%;
  //           border-top-right-radius: 5%;
  //           border-bottom-left-radius: 0%;
  //           border-top-left-radius: 0%;
  //           border-color: transparent;
  //           background-color: $light-secondary;
  //           color: $black;
  //           &.active-switch {
  //               background-color: $primary;
  //               color: white;
  //             }
  //   }
  // }   
}

.primary-button {
  color: white;
  background-color: #091F5C !important;
  border-radius: 5px;
  border-color: white;
  &:hover {
    background-color: white !important;
    color: #091F5C !important;
  }
}

.white-button {
  color:#6B799D;
  background-color:white !important;
  border-color: #6B799D;
}

.buttons.has-adds {
  >.button.is-right {
      margin: 0px !important;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      border-color: transparent;
      background-color: $light-secondary;
      color: rgb(180,180,190);
      width: auto!important;
      padding: 2% 4%;
      
      &.active-switch {
        background-color: $primary;
        color: white;
        transition: background .2s ease-out;
      }
    }
    >.button.is-left {
        margin: 0px !important;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-color: transparent;
        background-color: $light-secondary;
        color: rgb(180,180,190);
        width: auto!important;
        padding: 2% 4%;

        &.active-switch {
            background-color: $primary;
            color: white;
            transition: background .2s ease-out;
          }
}
}
.activity-btn-border {
  border-radius: 12px!important;
}