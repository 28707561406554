.footer__header {
  padding: 100px 30px;
}

.footer__button {
  background-color: #42a4d5;
  border: none;
  color: white;
  border-radius: 3px;
  padding: 16px 30px !important;
  font-size: 20px;
}

.footer__bottom {
  padding: 30px 30px;
  background-color: #001e5a;
  display: flex;
  align-items: center;
}

.footer__bottom p {
  color: white;
}

.footer__logo {
  width: 100px;
  object-fit: contain;
}

.footer__header button {
  background-color: #42a4d5;
  border: none;
  color: white;
  padding: 10px 35px;
  border-radius: 3px;
}

.footer__header h5 {
  color: #62666c;
  font-weight: 500;
}

.footer__handles {
  flex: 1;
}

.footer__handles>div {
  width: fit-content;
  margin: auto;
}

.footer__handle {
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 800px) {
  .footer__header button {
    width: 100%;
  }
}