.Dashboard {
    &__overview {
      background: #F8F9FE;
      border: 1px solid #F4F5FE;
      border-radius: 20px;
      height: 24rem;
      width: 95%;
      margin-right: 5px;
  
      button {
        color: #091F5C;
        background-color: white;
        border-radius: 10px;
      }
  
      button:hover {
        color: white;
        background-color: #091F5C;
        border-radius: 10px;
      }
  
    }
    
    &__activity {
      .activity-button {
        color: #6B799D!important;
        background-color: white!important;
        border: 1px solid #6B799D!important;
      }
      
      .activity-button:hover {
        color: white!important;
        background-color: #091F5C !important;
      }
      
    }
  
    &__debit-card {
      position: absolute;
      top: 160px;
      background: url("../assets/img/walletBgImg.png") center center no-repeat;
      background-size: cover;
      background-color: #091F5C;
      border: 1px solid #F4F5FE;
      border-radius: 10px;
      height: 228px;
      width: 37%;
      margin-left: -2%;
  
      >span {
        position: absolute;
        top: 35px;
        left: 20px;
        color: white;
      }
  
      .fund-button {
        position: absolute;
        height: 30px;
        width: 70px;
        border-radius: 10px;
        right: 20px;
        top: 30px;
        color: #091F5C;
      }

      >i {
        color: #091F5C;
      }
  
      .fund-button:hover {
        color: white;
        background-color: #091F5C !important;
      }
  
      span:nth-child(3) {
        position: absolute;
        top: 120px;
        left: 20px;
        color: white;
      }
    }
  
    &__updates {
      display: block;
      position: sticky;
      .updates-card {
        height: 70vh !important;
        width: 100% !important;
      }
  
      .updates-section {
        display: flex !important;
        flex-direction: column !important;
      }
  
      .updates-card-holder{
        height:100px;
      }
      .updates-icon-holder {
        height: 50px;
        width: 50px;
        padding: 12px;
        background-color: #F4F5FE;
      }
  
      .updates-card-holder {
        &:after{
          content: "";
          position: absolute;
          display: block;
          top: 122px;
          left: 40px;
          width: 2px;
          height: 50px;
          background-color: black;
          z-index: 1;
        }
        &:last-of-type::after {
          display: none;
        }
      }
      .newsletter-card {
        height: 20vh !important;
        width: 100% !important;
      }
    }
  }
  

  .activity-border {
    border: 1px solid #b5b5b5;
  }

  .ov-tabs {
    border-left: 1px solid #b5b5b5!important;
  }