@import "../styles/global.scss";

.Modal {
  .modal-background {
    background: rgba(0, 0, 0, 0.6);
  }
  .modal-card {
    max-width: 500px;
    border-radius: 25px;
    .modal-card-head {
      background-color: white;
      border: none;
      border-radius: 1px;
      > button {
        border: 1px solid $secondary !important;
        background-color: $secondary;
        &:hover {
          border: 1px solid $light-secondary !important;
          background-color: $light-secondary;
        }
      }

      .modal-card-title {
        color: #091f5c !important;
        font-size: x-large;
      }
    }
    .modal-card-body {
    }
    .modal-card-foot {
      background-color: white;
      border: none;
      border-radius: 1px;
      > div {
        width: 100%;
      }
      > div > button {
        border-radius: 25px !important;
        padding: 2% 8% !important;
        width: 30%;
        text-transform: capitalize !important;
      }
    }
  }
}
.btnCancel {
  padding: 2% 0 !important;
  border: none !important;
  color: #091f5c !important;
}
.label {
  font-weight: 500 !important;
  color: #091f5c !important;
}
