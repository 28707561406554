// .sticky-sidebar {
//     position: sticky !important;
//     display: inline-block !important;
//     vertical-align: top !important;
//     max-height: 100vh !important;
//     overflow-y: auto !important;
//     width: 200px !important;
//     top: 0 !important;
//     bottom: 0 !important;
// }

.DashboardSection {
  &__overview {
    background: #F8F9FE;
    border: 1px solid #F4F5FE;
    border-radius: 20px;
    height: 22rem;
    width: 95%;
    margin-right: 5px;

    button {
      color: #091F5C;
      background-color: white;
      border-radius: 10px;
    }

    button:hover {
      color: white;
      background-color: #091F5C;
      border-radius: 10px;
    }

  }
  
  &__activity {
    .activity-button {
      color: #091F5C;
      background-color: white !important;
    }
    
    .activity-button:hover {
      color: white;
      background-color: #091F5C !important;
    }
    
  }

  &__debit-card {
    position: absolute;
    top: 160px;
    background: #293A7C;
    border: 1px solid #F4F5FE;
    border-radius: 10px;
    height: 200px;
    width: 330px;

    >span {
      position: absolute;
      top: 35px;
      left: 20px;
      color: white;
    }

    .fund-button {
      position: absolute;
      height: 30px;
      width: 70px;
      border-radius: 10px;
      right: 20px;
      top: 30px;
    }

    .fund-button:hover {
      color: white;
      background-color: #091F5C !important;
    }

    span:nth-child(3) {
      position: absolute;
      top: 120px;
      left: 20px;
      color: white;
    }
  }

  &__table {

  }

  &__updates {
    display: block;
    position: sticky;
    .updates-card {
      height: 70vh !important;
      width: 100% !important;
    }

    .updates-section {
      display: flex !important;
      flex-direction: column !important;
    }

    .updates-card-holder{
      height:100px;
    }
    .updates-icon-holder {
      height: 50px;
      width: 50px;
      padding: 12px;
      background-color: #F4F5FE;
    }

    .updates-card-holder {
      &:after{
        content: "";
        position: absolute;
        display: block;
        top: 122px;
        left: 40px;
        width: 2px;
        height: 50px;
        background-color: black;
        z-index: 1;
      }
      // &:last-of-type::after {
      //   display: none;
      // }
    }
    .newsletter-card {
      height: 20vh !important;
      width: 100% !important;
    }
  }
}
